export const environment = {
  production: false,
  orgId: 'cims',
  enableABTesting: false,
  useABTestingStagingURL: false,

  clientId: 'mif_subscription_customers_authentication_flow',
  authServerUrl: 'https://hub-staging.storyshaper.io/greenv2',
  appBaseUrl: 'https://mif-subscription-cims.dev.storyshaper.io/', // The trailing slash is important
  apiBaseUrl: 'https://hub-staging.storyshaper.io/mif_subscription_for_green_v2',
  parrotApiBaseUrl: 'https://hub-staging.storyshaper.io/parrot_subscription_for_green_v2', // if run using 'start dotnet watch run'

  prospectClientId: 'mif_subscription_prospects_authentication_flow',
  prospectClientSecret: 'BookOldM@nSe@Cub@EH52'
};
