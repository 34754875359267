export const MifClientApplicationName = 'mif.subscription.user';
export const MifClientReturnUrlType = 'returnUrl';
export const MifClientQueryParameterNames = {
  ReturnUrl: MifClientReturnUrlType,
  Message: 'message'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

let mifClientApplicationPaths: MifClientApplicationPathsType = {
  DefaultLoginRedirectPath: '/membership-check',
  Login: `authentication/${LoginActions.Login}`,
  LoginCallback: `authentication/${LoginActions.LoginCallback}`,
  LogOut: `authentication/${LogoutActions.Logout}`,
  LoggedOut: `authentication/${LogoutActions.LoggedOut}`,
  LogOutCallback: `authentication/${LogoutActions.LogoutCallback}`
};

mifClientApplicationPaths = {
  ...mifClientApplicationPaths
};

interface MifClientApplicationPathsType {
  readonly DefaultLoginRedirectPath: string;
  readonly Login: string;
  readonly LoginCallback: string;
  readonly LogOut: string;
  readonly LoggedOut: string;
  readonly LogOutCallback: string;
}

export const MifClientApplicationPaths: MifClientApplicationPathsType = mifClientApplicationPaths;
