import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CheckboxFormControl } from '@form/checkbox-form-control/checkbox-form-control';
import { ComboBoxFormControl } from '@form/combobox-form-control/combobox-form-control';
import { DateFormControl } from '@form/date-form-control/date-form-control';
import { NumberFormControl } from '@form/number-form-control/number-form-control';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { collections } from '@shared/constants/collections';
import { cleanObj, getDobLimits } from '@shared/extensions/extensions';
import { DropDownFormControl } from '@shared/form/dropdown-form-control/dropdown-form-control';
import { NameWithDigitsFormControl } from '@shared/form/name-with-digits-form-control/name-with-digits-form-control';
import { AdvertisementSource, Choice, Offer, Summary } from '@shared/webapi/MIF.Subscription.WebApi';
import { AdvertisementSourceDataModel, Sources } from '@signature/signature.extentions';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SpinnerService } from 'projects/mif/app/services/spinner.service';
import { isCems, isGfd, isGpa, isPeri, isSameDate } from 'projects/mif/app/shared/extensions/extensions';
import { ProfileStepName } from 'projects/mif/app/shared/models/profile-step-name';
import { LabelTextPipe } from 'projects/mif/app/shared/pipes/label-text.pipe';
import { SettingsService } from 'projects/mif/app/shared/webapi/services/settings.service';
import { ProgressBarService } from '../../../services/progress-bar.service';
import { AlertModalComponent, modalTypes } from '../../../shared/components/modals/alert-modal/alert-modal.component';
import { SubscriptionHandler } from '../../../shared/components/subscriptionHandler';
import { constants } from '../../../shared/constants/constants';
import { RegistrationModel } from '../../../shared/extensions/user-data-extensions';
import { EmailFormControl } from '../../../shared/form/email-form-control/email-form-control';
import { MobileFormControl } from '../../../shared/form/mobile-form-control/mobile-form-control';
import { NameFormControl } from '../../../shared/form/name-form-control/name-form-control';
import { PhoneFormControl } from '../../../shared/form/phone-form-control/phone-form-control';
import { Informations } from '../../../shared/webapi/MIF.Subscription.Parrot';
import { ExternalServices, Profile } from '../../../shared/webapi/MIF.Subscription.WebApi';
import { CustomerService } from '../../../shared/webapi/services/customer.service';
import { WsCRMClientService } from '../../../shared/webapi/services/ws-crmclient.service';
import { WsDqeService } from '../../../shared/webapi/services/ws-dqe.service';
import { DobLimits, WsReferentielMetierService } from '../../../shared/webapi/services/ws-referentiel-metier.service';
import { AuthenticationResultStatus, MifAuthService } from '../../../auth/mif-auth.service';
import { MifClientReturnUrlType } from '../../../auth/mif-client-api-authorization.constants';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-membership-check',
  templateUrl: './membership-check.component.html',
  styleUrls: ['./membership-check.component.scss']
})
export class MembershipCheckComponent implements OnInit, OnDestroy {
  registrationForm: FormGroup;

  mifForm: FormGroup;
  prevMifFormData: any;
  advertisementSourceCodeControl: DropDownFormControl;
  promoCodeControl: NameWithDigitsFormControl;
  partnerCodeControl: NameWithDigitsFormControl;
  mifAdvisorCodeControl: NameWithDigitsFormControl;
  advSourceFirstNameControl: NameFormControl;
  advSourceLastNameControl: NameFormControl;
  advertisementSourceCodeCollection: Choice[];
  sponsorNumberControl: NameWithDigitsFormControl;
  memberNumberControl: NameWithDigitsFormControl;

  summaryConfiguration: Summary;
  manualOverrideOffer: EventEmitter<Offer> = new EventEmitter();
  offers: Offer[];
  offer: Offer;
  clientProfileForViewOffer: Profile;
  clientProfileAdvertisementSourceOrig: AdvertisementSource;
  expandContract: boolean;
  genre: string;
  faInfoCircle = faInfoCircle;
  faCheck = faCheck;
  constants = constants;
  source: any;
  sources: any = Sources;
  model: NgbDateStruct;
  date: { year: number };
  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  civilities: Informations[];

  dobMaxDate: Date;
  dobMinDateValidation: Date;
  dobMaxDateValidation: Date;
  dobLimits: DobLimits;

  clientChecking: boolean;
  isContract: boolean;

  checkedDobRegValue: any;
  dobRegValueIsNotInRangeLimit: boolean;
  dobRegistrationControl: DateFormControl;
  authorized: any;
  clientProfile: Profile;
  civilityCodeControl: ComboBoxFormControl;
  lastNameControl: NameFormControl;
  firstNameControl: NameFormControl;
  emailControl: EmailFormControl;
  mobilePhoneControl: MobileFormControl;
  phoneControl: PhoneFormControl;
  isMember = false;
  isCems = isCems();
  isGpa = isGpa();
  isGfd = isGfd();
  isPeri = isPeri();
  externalServicesConfiguration: ExternalServices;
  ProfileStepName = ProfileStepName;
  dataLoaded: boolean;
  regExpectedRetirementDateControl: DateFormControl;
  regRetirementYearsCountControl: NumberFormControl;
  expectedRetirementMinDate: Date = moment.utc().add(2, 'day').toDate();
  queryParams: Params;
  loginComplited: boolean = false;
  signInErrorMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private calendar: NgbCalendar,
    private wsReferentielMetierService: WsReferentielMetierService,
    private wsCRMClientService: WsCRMClientService,
    private bsModalService: BsModalService,
    private customerService: CustomerService,
    private progressBarService: ProgressBarService,
    private wsDqeService: WsDqeService,
    private settingsService: SettingsService,
    private labelTextPipe: LabelTextPipe,
    private mifClientAuthService: MifAuthService,
    private spinnerService: SpinnerService
  ) {
    this.expandContract = false;
  }

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(0);
    this.signInErrorMessage = null;
    await this.fetchInitData();
    this.initNewFormGroup();
    this.initControls();
    this.initSubscriptions();
    this.initMifFormSubscriptions();
    await this.restoreAnswersOnForm();

    const queryParams = this.activatedRoute.snapshot.queryParams;

    if (queryParams.code) {
      await this.completeLogin();
      this.loginComplited = true;
    }

    if (queryParams.userId) {
      this.initExistingUserData();
      this.expandContract = true;
      this.isContractChanged(true);
      this.loginComplited = true;
    }

    this.loginComplited = true;
  }

  async completeLogin() {
    const url = window.location.href;
    const result = await this.mifClientAuthService.completeSignIn(url);

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as completeSignIn never redirects.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        const tokenString = await this.mifClientAuthService.getAccessToken().toPromise();

        if (tokenString === null) {
          this.spinnerService.isLoading.next(false);
          break;
        }

        const tokenData = JSON.parse(atob(tokenString?.split('.')[1]));
        const clientNumberA = tokenData[constants.clientNumberATokenKey];
        this.isContractChanged(true);
        this.clientProfile = await this.customerService.getClient(clientNumberA);
        this.initExistingUserData();
        this.expandContract = true;
        this.spinnerService.isLoading.next(false);
        break;

      case AuthenticationResultStatus.Fail:
        this.signInErrorMessage = result.message;
        this.isContract = true;
        this.goToSignInGreen();
        break;
    }

    this.restoreAdvertisementSourceData();
  }

  async fetchInitData(): Promise<void> {
    this.summaryConfiguration = await this.settingsService.getSummaryConfiguration();
    this.externalServicesConfiguration = await this.settingsService.getExternalServicesConfiguration();
    this.dobLimits = await this.wsReferentielMetierService.getDobLimits();
    this.civilities = await this.wsCRMClientService.getCivilities();

    this.initDobLimits();
    this.initAdvertisementSourceCodeCollection();
  }

  initDobLimits(): void {
    const limits = getDobLimits(this.dobLimits);

    this.dobMinDateValidation = limits.dobMinDateValidation;
    this.dobMaxDateValidation = limits.dobMaxDateValidation;
    this.dobMaxDate = limits.dobMaxDate;
  }

  async restoreAnswersOnForm(): Promise<void> {
    this.clientProfile = await this.customerService.getUserData();
    this.clientProfileForViewOffer = Object.assign({}, this.clientProfile);
    this.clientProfileAdvertisementSourceOrig = cleanObj(Object.assign({}, this.clientProfileForViewOffer.advertisementSource));

    if (this.clientProfile) {
      this.isContractChanged(!!this.clientProfile.socialNumberA);

      const registrationModel = this.customerService.getRegistrationModel(); // in any cases we need to set reg data
      this.registrationForm.patchValue(registrationModel);
    } else {
      this.isContractChanged(false);
    }

    this.dataLoaded = true;
  }

  initNewFormGroup(): void {
    this.registrationForm = new FormGroup({
      civilityCode: new ComboBoxFormControl(true, false, {
        collection: this.civilities,
        valuePropertyName: constants.informationValueField,
        keyPropertyName: constants.referenceKeyField
      }),
      lastName: new NameFormControl(true, false, { capitalizeAll: true, maxLength: constants.maxLength.lastName }),
      firstName: new NameFormControl(true, false, { capitalizeAll: true, maxLength: constants.maxLength.firstName }),
      dateOfBirth: new DateFormControl(true, false, {
        minDateErrorMessage: this.labelTextPipe.transform('HOME.AlreadyClientDateOfBirthError'),
        maxDateErrorMessage: this.labelTextPipe.transform('HOME.AlreadyClientDateOfBirthError')
      }),
      email: new EmailFormControl(true, false),
      mobilePhone: new MobileFormControl(true, false, {
        hideErrorMark: true,
        hideSuccessMark: true
      }),
      phone: new PhoneFormControl(false, false, {
        hideErrorMark: true,
        hideSuccessMark: true
      }),
      receiveEmail: new CheckboxFormControl(false, false),
      receiveSms: new CheckboxFormControl(false, false)
    });

    if (this.isPeri) {
      const regRetirementYearsCountControl = new NumberFormControl(false, false, null, null, true, { min: 0, max: 100 });
      const regExpectedRetirementDateControl = new DateFormControl(false, false, {
        minDateErrorMessage: this.labelTextPipe.transform('Common.dateEnteredMustGreaterThanToday'),
        maxDateErrorMessage: this.labelTextPipe.transform('Common.dateEnteredMustGreaterThanToday')
      });

      const authRetirementYearsCountControl = new NumberFormControl(false, false, null, null, true, { min: 0, max: 100 });
      const authExpectedRetirementDateControl = new DateFormControl(false, false, {
        minDateErrorMessage: 'La date renseignée doit être supérieure à la date du jour',
        maxDateErrorMessage: 'La date renseignée doit être supérieure à la date du jour'
      });

      this.registrationForm.addControl('expectedRetirementDate', regExpectedRetirementDateControl);
      this.registrationForm.addControl('retirementYearsCount', regRetirementYearsCountControl);
    }

    this.mifForm = new FormGroup({
      advertisementSourceCode: new DropDownFormControl(false, false, {
        collection: this.advertisementSourceCodeCollection,
        valuePropertyName: 'label',
        keyPropertyName: 'code'
      }),
      promoCode: new NameWithDigitsFormControl(false, false, {
        hideErrorMessages: false,
        incorrectErrorMessage: collections.messages.promoCodeIncorrectMessage
      }),
      partnerCode: new NameWithDigitsFormControl(false, false),
      sponsorNumber: new NumberFormControl(false, false, 8, 8, true, {
        min: 1000000,
        minValueErrorMessage: collections.messages.sponsorNumberError,
        requiredErrorMessage: collections.messages.sponsorNumberError
      }),
      memberNumber: new NumberFormControl(false, false, null, 3, true, {
        requiredErrorMessage: collections.messages.memberNumberError
      }),
      firstName: new NameFormControl(false, false, { capitalizeAll: true, hideSuccessMark: false, hideErrorMessages: true, maxLength: constants.maxLength.firstName }),
      lastName: new NameFormControl(false, false, { capitalizeAll: true, hideSuccessMark: true, hideErrorMessages: true, maxLength: constants.maxLength.lastName }),
      mifAdvisorCode: new NumberFormControl(false, false, 4, 4, true, {
        min: 1000,
        minValueErrorMessage: collections.messages.mifLorsCodeError,
        requiredErrorMessage: collections.messages.mifLorsCodeError
      })
    });
  }

  initControls(): void {
    this.dobRegistrationControl = this.registrationForm.get('dateOfBirth') as DateFormControl;
    this.civilityCodeControl = this.registrationForm.get('civilityCode') as ComboBoxFormControl;
    this.lastNameControl = this.registrationForm.get('lastName') as NameFormControl;
    this.firstNameControl = this.registrationForm.get('firstName') as NameFormControl;
    this.emailControl = this.registrationForm.get('email') as EmailFormControl;
    this.mobilePhoneControl = this.registrationForm.get('mobilePhone') as MobileFormControl;
    this.phoneControl = this.registrationForm.get('phone') as PhoneFormControl;
    if (this.isPeri) {
      this.regExpectedRetirementDateControl = this.registrationForm.get('expectedRetirementDate') as DateFormControl;
      this.regRetirementYearsCountControl = this.registrationForm.get('retirementYearsCount') as NumberFormControl;
    }

    this.advertisementSourceCodeControl = this.mifForm.get('advertisementSourceCode') as DropDownFormControl;
    this.promoCodeControl = this.mifForm.get('promoCode') as NameWithDigitsFormControl;
    this.partnerCodeControl = this.mifForm.get('partnerCode') as NameWithDigitsFormControl;
    this.sponsorNumberControl = this.mifForm.get('sponsorNumber') as NameWithDigitsFormControl;
    this.memberNumberControl = this.mifForm.get('memberNumber') as NameWithDigitsFormControl;
    this.advSourceFirstNameControl = this.mifForm.get('firstName') as NameFormControl;
    this.advSourceLastNameControl = this.mifForm.get('lastName') as NameFormControl;
    this.mifAdvisorCodeControl = this.mifForm.get('mifAdvisorCode') as NameWithDigitsFormControl;
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      this.queryParams = queryParams;
    });

    this.subscriptionHandler.subscriptions = this.dobRegistrationControl.statusChanges.subscribe((status: string) => {
      const dobValue = this.dobRegistrationControl.value;

      if (status === constants.validStatus && dobValue) {
        if (this.checkedDobRegValue !== dobValue) {
          this.checkedDobRegValue = dobValue;
          if (moment.utc(dobValue).isBetween(this.dobMinDateValidation, this.dobMaxDateValidation) || isSameDate(dobValue, this.dobMinDateValidation)) {
            this.dobRegValueIsNotInRangeLimit = false;
          } else {
            this.dobRegValueIsNotInRangeLimit = true;
          }
        }
      } else {
        this.dobRegValueIsNotInRangeLimit = false;
      }
    });

    if (this.isPeri) {
      // reg form
      this.subscriptionHandler.subscriptions = this.regExpectedRetirementDateControl?.valueChanges.subscribe((value: string) => {
        const isValid = this.regExpectedRetirementDateControl.valid;

        if (isValid && value) {
          const yearsCount = Math.abs(moment.utc(new Date()).diff(moment.utc(value).add(1, 'day'), 'years', false));
          this.regRetirementYearsCountControl.patchValue(yearsCount, { emitEvent: false });
        }
      });

      this.subscriptionHandler.subscriptions = this.regRetirementYearsCountControl?.valueChanges.subscribe((value: number) => {
        const isValid = this.regRetirementYearsCountControl.valid;

        if (isValid && value) {
          const date = moment.utc().add(value, 'years').add(1, 'day');
          this.regExpectedRetirementDateControl.patchValue(date, { emitEvent: false });
        } else if (value === 0) {
          const date = moment.utc().add(1, 'day');
          this.regExpectedRetirementDateControl.patchValue(date, { emitEvent: false });
        }
      });
    }
  }

  async goToSignInGreen() {
    this.spinnerService.isLoading.next(true);
    const url = window.location.href;
    const state: INavigationState = { returnUrl: url };
    const authenticationResult = await this.mifClientAuthService.signIn(state);
  }

  initMifFormSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.mifForm?.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe((data: any) => {
      if (this.prevMifFormData?.promoCode !== data.promoCode) {
        // cause no need to emit the changes in case we edit promoCode
        this.prevMifFormData = Object.assign({}, data);
        return;
      }

      if (JSON.stringify(this.prevMifFormData) !== JSON.stringify(data)) {
        if (this.prevMifFormData?.promoCode && this.prevMifFormData?.promoCode !== data.promoCode) {
          this.prevMifFormData = Object.assign({}, data);
          return;
        }

        this.prevMifFormData = Object.assign({}, data);

        if (data.advertisementSourceCode === Sources.MEMBRE_CORRESPONDANT) {
          this.mifAdvisorCodeControl.markAsNotRequred();

          if (data.memberNumber) {
            this.advSourceFirstNameControl.markAsNotRequred();
            this.advSourceFirstNameControl.markAsTouched();
            this.advSourceLastNameControl.markAsNotRequred();
            this.advSourceLastNameControl.markAsTouched();
          } else {
            this.advSourceFirstNameControl.markAsRequred();
            this.advSourceLastNameControl.markAsRequred();
          }
          if (this.advSourceFirstNameControl.value || this.advSourceLastNameControl.value) {
            this.memberNumberControl.markAsNotRequred();
          } else {
            this.memberNumberControl.markAsRequred();
          }
          if (!data.memberNumber && !data.firstName && !data.lastName) {
            this.advSourceFirstNameControl.markAsRequred();
            this.advSourceLastNameControl.markAsRequred();
            this.memberNumberControl.markAsRequred();
          }
        }
        if (data.mifAdvisorCode) {
          this.mifAdvisorCodeControl.markAsRequred();
        } else {
          this.mifAdvisorCodeControl.markAsNotRequred();
        }
        // this.cdr.detectChanges();

        const advertisementSourceModel = this.mifForm.getRawValue() as AdvertisementSourceDataModel;
        this.clientProfileForViewOffer = this.customerService.updateAdvertisementSourceDataModelWithoutSaving(this.clientProfile, advertisementSourceModel);
      }
    });

    this.subscriptionHandler.subscriptions = this.promoCodeControl.valueChanges.subscribe((promoCodeValue: string) => {
      const offerWithCode = this.offers?.find((offer: Offer) => {
        return offer.promoCode === promoCodeValue;
      });

      if (promoCodeValue === null || promoCodeValue?.trim() === '') {
        this.promoCodeControl.markAsValid();
        this.mifForm.updateValueAndValidity();
        return;
      }

      this.promoCodeControl.markValidOrNot(!!offerWithCode);
      this.mifForm.updateValueAndValidity();

      if (offerWithCode) {
        this.manualOverrideOffer.emit(offerWithCode);
      }
    });
  }

  onOffersItem(item: Offer): void {
    const objForComparation = cleanObj(Object.assign({}, this.clientProfile?.advertisementSource));

    // check in case just return back and we need to restore the data
    if (
      this.clientProfile?.advertisementSource === undefined ||
      this.clientProfile?.advertisementSource?.advertisementSourceCode === undefined ||
      JSON.stringify(objForComparation) !== JSON.stringify(this.clientProfileAdvertisementSourceOrig)
    ) {
      this.offer = item;

      if (item) {
        this.promoCodeControl.patchValue(item.promoCode);
      } else {
        this.promoCodeControl.patchValue(null);
      }
    }
  }

  onOffersItems(items: Offer[]): void {
    this.offers = items;
    this.restorePromoCode();
  }

  onSourceChange(event: any, firstTime: boolean = false): void {
    this.source = event.value;

    if (!firstTime) {
      this.sponsorNumberControl.patchValue(null);
      this.partnerCodeControl.patchValue(null);
      this.memberNumberControl.patchValue(null);
      this.advSourceFirstNameControl.patchValue(null);
      this.advSourceLastNameControl.patchValue(null);
    }

    switch (this.source) {
      case Sources.PARRAINAGE: {
        this.sponsorNumberControl.markAsRequred();
        this.memberNumberControl.markAsNotRequred();
        this.partnerCodeControl.markAsNotRequred();
        this.advSourceFirstNameControl.markAsNotRequred();
        this.advSourceLastNameControl.markAsNotRequred();
        break;
      }
      case Sources.PARTENAIRE: {
        this.partnerCodeControl.markAsRequred();
        this.sponsorNumberControl.markAsNotRequred();
        this.memberNumberControl.markAsNotRequred();
        this.advSourceFirstNameControl.markAsNotRequred();
        this.advSourceLastNameControl.markAsNotRequred();
        break;
      }
      case Sources.MEMBRE_CORRESPONDANT: {
        this.memberNumberControl.markAsRequred();
        this.sponsorNumberControl.markAsNotRequred();
        this.partnerCodeControl.markAsNotRequred();
        if (this.memberNumberControl.value) {
          this.advSourceFirstNameControl.markAsNotRequred();
          this.advSourceLastNameControl.markAsNotRequred();
        } else {
          this.advSourceFirstNameControl.markAsRequred();
          this.advSourceLastNameControl.markAsRequred();
        }
        break;
      }
      default: {
        this.sponsorNumberControl.markAsNotRequred();
        this.partnerCodeControl.markAsNotRequred();
        this.memberNumberControl.markAsNotRequred();
        this.advSourceFirstNameControl.markAsNotRequred();
        this.advSourceLastNameControl.markAsNotRequred();
        this.sponsorNumberControl.patchValue(null);
        this.partnerCodeControl.patchValue(null);
        this.memberNumberControl.patchValue(null);
        this.advSourceFirstNameControl.patchValue(null);
        this.advSourceLastNameControl.patchValue(null);
        break;
      }
    }
  }

  restoreAdvertisementSourceData() {
    const model = this.customerService.getAdvertisementSourceDataModel();

    if (!model) {
      return;
    }

    this.mifForm.patchValue(model);

    this.onSourceChange({ value: model.advertisementSourceCode }, true);

    const offerWithCode = this.offers?.find((offer: Offer) => {
      return offer.promoCode === model.promoCode;
    });

    if (model.promoCode && !!offerWithCode) {
      this.promoCodeControl.patchValue(model.promoCode);
    } else {
      this.promoCodeControl.patchValue(null);

      if (this.offer) {
        this.promoCodeControl.patchValue(this.offer.promoCode);
      }
    }
  }

  restorePromoCode() {
    const model = this.customerService.getAdvertisementSourceDataModel();

    if (!model) {
      return;
    }

    const offerWithCode = this.offers?.find((offer: Offer) => {
      return offer.promoCode === model.promoCode;
    });

    if (model.promoCode && !!offerWithCode) {
      this.promoCodeControl.patchValue(model.promoCode);
    } else {
      this.promoCodeControl.patchValue(null);

      if (this.offer) {
        this.promoCodeControl.patchValue(this.offer.promoCode);
      }
    }
  }

  initAdvertisementSourceCodeCollection(): void {
    this.advertisementSourceCodeCollection = this.summaryConfiguration.possibleChoices.filter((item: Choice) => {
      if (this.isMember) {
        return item.code !== constants.sponsorshipCode;
      } else {
        return item;
      }
    });

    this.advertisementSourceCodeControl?.patchValue(null);
    this.advertisementSourceCodeControl?.setCollection(this.advertisementSourceCodeCollection);
  }

  get dobErrorBlockText(): string {
    let text = this.labelTextPipe.transform('Common.dobErrorBlockText');

    if (this.isPeri) {
      text = this.labelTextPipe.transform('Common.dobErrorBlockTextPeri', this.dobLimits.maxAge);
    }

    return text;
  }

  get isRegistrationFormValid(): boolean {
    return this.registrationForm.valid && !this.dobRegValueIsNotInRangeLimit && !this.clientChecking;
  }

  get isAuthValidateVisible(): boolean {
    return !this.authorized || this.clientChecking;
  }

  async emailChange(event?: any): Promise<void> {
    if (event && event !== '' && this.emailControl.status === constants.validStatus) {
      if (this.externalServicesConfiguration.dqeValidation === false) {
        return;
      }

      const emailICheck = await this.wsDqeService.validateEmail(this.emailControl.value);

      if (emailICheck.hasError) {
        this.emailControl.setErrors({ emailRemoteValidator: true });
      } else {
        this.emailControl.setErrors(null);
      }
    }
  }

  selectToday(): void {
    this.model = this.calendar.getToday();
  }

  isContractChanged(exist: boolean): void {
    this.isContract = exist;
    this.registrationForm.markAsPristine();
    this.registrationForm.markAsUntouched();

    if (exist) {
      this.isMember = true;

      this.dobRegistrationControl.markAsNotRequred();
      this.civilityCodeControl.markAsNotRequredAndDisable();
      this.lastNameControl.markAsNotRequredAndDisable();
      this.firstNameControl.markAsNotRequredAndDisable();

      if (this.isPeri) {
        this.regRetirementYearsCountControl?.markAsNotRequred();
        this.regExpectedRetirementDateControl?.markAsNotRequred();
      }
    } else {
      this.registrationForm.updateValueAndValidity();
      this.isMember = false;

      this.dobRegistrationControl.markAsRequred();
      this.civilityCodeControl.markAsRequredAndEnable();
      this.lastNameControl.markAsRequredAndEnable();
      this.firstNameControl.markAsRequredAndEnable();

      if (this.isPeri) {
        this.regRetirementYearsCountControl?.markAsRequred();
        this.regExpectedRetirementDateControl?.markAsRequred();
      }
    }

    this.initAdvertisementSourceCodeCollection();
  }

  get showForm(): boolean {
    return this.isContract !== undefined;
  }

  initExistingUserData(): void {
    this.dobRegistrationControl.patchValue(this.clientProfile.person.dateOfBirth);
    this.civilityCodeControl.patchValue(this.clientProfile.person.civilityCode);
    this.lastNameControl.patchValue(this.clientProfile.person.lastName);
    this.firstNameControl.patchValue(this.clientProfile.person.firstName);
    this.emailControl.patchValue(this.clientProfile.contact.email?.trim().replace(/\s\s+/g, ' '));
    this.mobilePhoneControl.patchValue(this.clientProfile.contact.mobilePhone?.trim().replace(/\s\s+/g, ' '));
    this.phoneControl.patchValue(this.clientProfile.contact.phone?.trim().replace(/\s\s+/g, ' '));

    setTimeout(() => {
      this.emailControl.markAsTouched();
      this.mobilePhoneControl.markAsTouched();
      this.phoneControl.markAsTouched();
    }, 1);
  }

  notAuthorizated(): void {
    this.expandContract = false;
    this.clientChecking = false;
    this.showInvalidModal();
  }

  showInvalidModal(): void {
    this.bsModalService.show(AlertModalComponent, {
      initialState: {
        type: modalTypes.error,
        title: this.labelTextPipe.transform('HOME.ErrorClientNotFound')
      },
      class: 'modal-lg'
    });
  }

  checkAndExtendModelByPeriData(model: RegistrationModel): void {
    if (this.isPeri) {
      model.expectedRetirementDate = this.regExpectedRetirementDateControl.value;
    }
  }

  async onSubmitRegistration(): Promise<void> {
    const phone = this.phoneControl.value;
    const mobile = this.mobilePhoneControl.value;

    let phoneInValid = false;
    let mobileInValid = false;

    if (phone && phone !== '' && this.externalServicesConfiguration.dqeValidation === true) {
      phoneInValid = !(await this.wsDqeService.validatePhoneNumber(phone));
      if (phoneInValid) {
        this.phoneControl.setErrors({ phoneRemoteValidator: true });
      }
    }

    if (mobile && mobile !== '' && this.externalServicesConfiguration.dqeValidation === true) {
      mobileInValid = !(await this.wsDqeService.validatePhoneNumber(mobile));
      if (mobileInValid) {
        this.mobilePhoneControl.setErrors({ phoneRemoteValidator: true });
      }
    }

    if (!phoneInValid && !mobileInValid) {
      const model = Object.assign({ isMember: this.isMember }, this.registrationForm.getRawValue()) as RegistrationModel;

      if (this.isMember) {
        model.dateOfBirth = this.registrationForm.get('dateOfBirth').value;
      }

      this.checkAndExtendModelByPeriData(model);

      const userId = await this.customerService.updateRegistrationModel(model);

      await this.checkAndSaveUtms();

      const advertisementSourceModel = this.mifForm.getRawValue() as AdvertisementSourceDataModel;
      await this.customerService.updateAdvertisementSourceDataModel(advertisementSourceModel);

      this.router.navigate(['/residence-questions'], { queryParams: { userId } });
    }
  }

  async checkAndSaveUtms(): Promise<void> {
    if (this.queryParams) {
      const utmParams = Object.keys(this.queryParams).filter((key: string) => {
        return key.indexOf('utm') !== -1;
      });

      if (utmParams?.length > 0) {
        let utmsString = '';

        utmParams.forEach((utm: string, index: number) => {
          utmsString += `${utm}=${this.queryParams[utm]}`;
          if (index !== utmParams.length - 1) {
            utmsString += '&';
          }
        });

        await this.customerService.updateUTMData(utmsString);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }
}

interface INavigationState {
  [MifClientReturnUrlType]: string;
}
